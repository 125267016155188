export default class Palette {

    constructor( colors ) {

        this.colors = colors || {
            a: '#d9cdc0',
            b: '#d9d0c0',
            c: '#857f6e',
            d: '#fffcf8',
            e: '#fffaf0',
            f: '#d9d5c0',
        }

    }



    setColors( colors ) {

        this.colors = colors

    }

}