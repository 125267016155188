
import Konva from 'konva'


/**
 * FabricRender
 *
 * fabricRender.getLayerU().toDataURL({ pixelRatio: 1 })
 * fabricRender.getLayerV().toDataURL({ pixelRatio: 1 })
 *
 */
export default class FabricRender {

    constructor( stage , fabric , palette ) {

        this._debug = !true

        this.stage = stage
        this.fabric = fabric
        this.setPalette( palette )

        this.layerU = new Konva.Layer()
        this.groupU = new Konva.Group()
        this.layerU.add( this.groupU )

        this.layerV = new Konva.Layer()
        this.groupV = new Konva.Group()
        this.layerV.add( this.groupV )

        this.stage.add( this.layerU )
        this.stage.add( this.layerV )

    }



    setPalette( palette ) {
        //console.log('palette',palette)
        this.palette = palette

    }



    /**
     * getLayerU
     * @return {Konva.Layer}
     */
    getLayerU() {
        return this.layerU
    }



    /**
     * getLayerV
     * @return {Konva.Layer}
     */
    getLayerV() {
        return this.layerV
    }



    /**
     * render
     *
     * @return {*}
     */
    render() {

        this.groupU.destroy()
        this.groupU = new Konva.Group()
        this.layerU.add(this.groupU)

        let g = this.groupU

        let l = null

        if (this._debug) {
            console.log(
                this.fabric.threadU.length,
                this.fabric.threadV.length
            )
        }

        for ( let i=0 ; i < this.fabric.threadU.length ; i++ ) {

            // get letter

            l = this.fabric.threadU.substr(i,1)
            //console.log(l,this.palette)

            let line = new Konva.Line({
                points: [ 0 , i , this.fabric.threadV.length , i ],
                stroke: this.palette[l], //'#'+l+'00',//(i%2==0)?'#f00':'#a2a2a2',
                strokeWidth: 1,
            })

            g.add(line)

        }

        this.layerU.draw()


        this.groupV.destroy()
        this.groupV = new Konva.Group()
        this.layerV.add(this.groupV)

        let g1 = this.groupV

        for ( let i=0 ; i < this.fabric.threadV.length ; i++ ) {

            // get letter

            l = this.fabric.threadV.substr(i,1)
            //console.log(l,this.palette[l])

            let line = new Konva.Line({
                points: [i, 0, i, this.fabric.threadU.length],
                stroke: this.palette[l],//'#'+l+'00',//(i%2==0)?'#f00':'#a2a2a2',
                strokeWidth: 1,
            })

            g1.add(line)


        }

        this.layerV.draw()

        //console.log(this.stage,this.stage.toJSON())

        return this.stage

    }


}