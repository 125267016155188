<template>
  <header class="headbar noselect">
    <h1 class="app app--title">abstraktion | fabric design</h1>

    <div class="app app--menu">
      <font-awesome-icon
        icon="question-circle"
        size="1x"
        class="header__icon"
        v-if="$route.path == '/app'"
        @click="switchModal()"
      />

      <router-link v-if="store.getters.isClient" to="/app">
        <font-awesome-icon icon="marker" size="1x" class="header__icon" />
      </router-link>

      <router-link to="/profile">
        <font-awesome-icon icon="user" size="1x" class="header__icon" />
        <!--div :style="{'background-image': 'url(' + user.photoURL + ')' }" class="header__userprofile">

                </div-->
      </router-link>

      <!--router-link
                    to="/settings">
                <font-awesome-icon icon="user" size="1x" class="header__icon" />
            </router-link-->

      <font-awesome-icon
        icon="sign-out-alt"
        size="1x"
        class="header__icon"
        @click="logout"
      />
    </div>
  </header>
</template>

<script>
//import { auth } from '../firestore/firestore'
//import { mapState } from 'vuex'

import firebase from "firebase";

import store from "../store";

export default {
  name: "headbar",
  data() {
    return {
      user: null,
      store: store,
    };
  },
  methods: {
    getUrl() {
      if (window.location.hostname == "localhost")
        return "http://localhost:8081/";
      else return "https://color.abstraktion.io/";
    },

    created() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.user = user;
        }
      });
    },

    switchModal() {
      this.$parent.$refs.razor.isCardModalActive =
        !this.$parent.$refs.razor.isCardModalActive;
    },

    logout() {
      this.$store.dispatch("logout", {});
    },
  },
};
</script>

<style lang="scss">
.headbar {
  $primary: #4099ff;

  a,
  a:visited,
  .header__icon {
    color: #777;
    transition: all 1s;
  }

  .router-link-exact-active svg {
    color: $primary;
  }

  a:hover,
  .header__icon:hover {
    color: #333;
    transition: all 1s;
  }

  font-family: "Jost", sans-serif;
  z-index: 100;
  //position: fixed;
  background-color: rgba(255, 255, 255, 0.95);
  //   top: 0;
  //   left: 0;
  width: 100%;
  border-bottom: 1px solid #eee;

  height: 40px;
  padding: 10px;

  display: inline-flex;

  h1 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .app {
    /*font-size: .5rem;
            text-align: center;

            $size: 36px;

            width:$size;
            height:$size;

            margin: 0 1px;*/

    text-transform: uppercase;
    margin-right: 16px;

    &--menu {
      position: absolute;
      right: 0;
      top: 0;
      svg {
        margin: 0 4px;
      }
    }

    &--fabric {
      //background-color: #f00;
    }

    &--palette {
      //background-color: #0f0;
    }
  }

  .header {
    &__icon {
      cursor: pointer;
    }
    &__userprofile {
      display: inline-flex;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #eee;
      background-position: center;
      background-size: cover;
    }
  }
}
</style>