<template>

    <div class="palette__box">

        <div class="palette__add">
            <font-awesome-icon
                    icon="plus-circle" size="3x"
                    @click="createPalette()"
                    class = "palette__icon palette__icon--color"
                    :style="{color: $parent.colorModify}"
            />
        </div>

        <div v-for="( cc , palette_id ) in palette" class="palette__row noselect">

            <font-awesome-icon icon="arrow-circle-left" size="3x"
                               @click="applyPalette( palette_id )"
                               :class = "{ palette__icon: true, selected: (palette_selected == palette_id)}"
            />

            <div
                 :data-key="palette_id"
                 v-for="( c, color_id ) in cc"
                 :style="{'backgroundColor': c}"
                 :key="color_id"
                 @click="select( palette_id , color_id )"
                 :class = "{ palette__color: true, noselect: true, selected: (selected_color == color_id && selected_palette == palette_id)}"
            >
                {{ color_id }}
            </div>

        </div>



    </div>

</template>

<script>

    import eventbus from "../../bus/eventbus";

    export default {
        name: 'palette',
        data() {
            return {

                selected_color      :   null,
                selected_palette    :   0,
                palette_selected       :   0,

                //palette: this.$store.state.palette

            }
        },
        /*watch: {

            '$store.state.palette': function ( v ) {

                console.log(v,this.$store.state.palette)

                //this.colors = this.$store.state.palette

            }
        },*/



        mounted() {

            //console.log(localStorage.palette_selected)

            if (localStorage.palette_selected) this.palette_selected = (localStorage.palette_selected)

            eventbus.$on('bus_paletteModifyColor', ( o ) => {
                //console.log(o)
                this.modifyColor( o.modify_color_hex )
            })

            eventbus.$on('bus_paletteModifyAllColors', ( o ) => {
                //console.log(o)
                this.palette = o
            })



        },

        methods: {

            createPalette() {

                //console.log('createPalette')

                eventbus.$emit('bus_createPalette', {

                })

                this.selected_palette = 0//this.palette.length-1
                this.palette_selected = 0//this.palette.length-1

            },

            select( palette_id , color_id ) {

                //console.log(palette_id , color_id)

                if (this.selected_color == color_id && this.selected_palette == palette_id) {
                    // unselect
                    this.selected_color = null
                    //console.log('unselect')
                } else {
                    this.selected_color = color_id

                    eventbus.$emit('bus_modifyColorColorPicker', {
                        selected_color_hex : this.palette[palette_id][color_id]
                    })

                }

                this.selected_palette = palette_id

            },

            applyPalette( palette_id ) {

                // bus

                this.palette_selected = palette_id

                eventbus.$emit('bus_applyPalette', {
                    selected_palette : this.palette_selected
                })

            },

            modifyColor( modify_color_hex ) {

                if (this.selected_color) {

                    // change color

                    this.palette[this.selected_palette][this.selected_color] = modify_color_hex

                }

            }

        },

        props: [
            'palette'
        ],

        watch: {

            palette_selected( index ) {
                //console.log(index,'--')
                localStorage.palette_selected = (index)
            }

        }

    }

</script>

<style lang="scss">

    svg.palette__icon--color {

        //border: 1px solid #eee;
    }

    .palette {

        $size: 26px;

        //display: inline-flex;
        font-size: .8rem;

        //margin-bottom: 15px;

        &__box {
            height:100%;
        }

        &__add {
            position:absolute;
            left: -14px;
        }

        &__row {
            //display: flex;
            text-align: left;
            width: 100%;
        }

        &__icon {
            width: 20px !important;
            height: 20px;
            margin: 3px;
            cursor: pointer;
            color: #c1c1c1;
            transition: all 800ms;
            opacity: .3;
            float: left;

            &:hover {
                opacity: 1;
            }

            &.selected {
                color: #000000;
                opacity: 1;
            }

            &--color {
                color:#ff0000;
                opacity: 1;
                filter: brightness(0.8);

            }
        }



        &__apply {
            border-radius: 50%;
            border: 1px solid #aaa;
            width: $size;
            height: $size;
            margin:1px;
            color:#fff;
            padding: 2px 8px;
            cursor: pointer;

            &.selected, &:hover {
                border: 1px solid rgba(0, 0, 0, 0.71);
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                transition: border 500ms;
            }
        }

        &__color {

            display: block;
            float: left;

            border-radius: 50%;
            border: 1px solid #aaa;
            width: $size;
            height: $size;
            margin:1px;
            color:#fff;
            padding: 2px 8px;
            cursor: pointer;
            transition: border 500ms;

            &.selected, &:hover:not(.selected) {
                border: 1px solid rgba(0, 0, 0, 0.71);
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                transition: border 500ms;
            }

        }

    }

    .noselect {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }


</style>