<template>

    <div class="">


        <b-modal v-model="isCardModalActive" width="50%" scroll="keep">
            <div class="card">
                <div class="card-image">
                    <div style="padding:56.25% 0 0 0;position:relative;">
                        <iframe src="https://player.vimeo.com/video/522356075?autoplay=1&loop=1&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <!--div class="card-content">
                    <div class="content">

                    </div>
                </div-->
            </div>
        </b-modal>



        <div :class="{'ux-input':true, full: !panel_open}">

            <div class="fabric__editable">

                <div class="fabric__formula">

                    <span @click="threadCopyUtoV()" class="fabric__inject">
                        <font-awesome-icon icon="chevron-circle-down" size="1x"

                        />
                    </span>

                    <span @click="edit_u = !edit_u" class="fabric__inject">
                        <font-awesome-icon v-if="edit_u" icon="edit" size="1x"/>
                        <font-awesome-icon v-if="!edit_u" icon="arrows-alt-h" size="1x"/>
                    </span>

                    <draggable group="shared" v-if="!edit_u" v-model="fabric.threadUArray" @start="drag=true"
                               @end="drag=false;fabricThreadReorder()" class="fabric__draggable">
                        <span v-for="(uv,id) in thread_u_editable" :style="{'backgroundColor':uv.color}"
                              @click="threadMoveU(id)"
                              class="thread__unit noselect"
                        >
                            <span class="thread__unit-count">{{ uv.count }}</span>
                            <span class="thread__unit-thread">{{ uv.thread }}</span>
                        </span>
                    </draggable>

                    <span v-else>
                        <b-input v-model="fabric.u" v-on:keyup.native="fabricUpdate"></b-input>
                    </span>

                    <span class="thread__count"
                          v-if="fabric.u !== ''"
                    >
                        {{ this.fabric.getHeight() }} threads
                    </span>

                </div>

                <div class="fabric__formula">

                    <span @click="threadCopyVtoU()" class="fabric__inject">
                        <font-awesome-icon icon="chevron-circle-up" size="1x"

                        />
                    </span>

                    <span @click="edit_v = !edit_v" class="fabric__inject">
                        <font-awesome-icon v-if="edit_v" icon="edit" size="1x"/>
                        <font-awesome-icon v-if="!edit_v" icon="arrows-alt-h" size="1x"/>
                    </span>

                    <!-- contenteditable="true" -->

                    <draggable group="shared" v-if="!edit_v" v-model="fabric.threadVArray" @start="drag=true"
                               @end="drag=false;fabricThreadReorder()"
                               class="fabric__draggable">
                        <span v-for="(uv,id) in thread_v_editable" :style="{'backgroundColor':uv.color}"
                              @click="threadMoveV(id)"
                              class="thread__unit noselect"
                        >
                            <span class="thread__unit-count"  >{{ uv.count }}</span>
                            <span class="thread__unit-thread" >{{ uv.thread }}</span>
                        </span>
                    </draggable>

                    <span v-else>
                        <b-input v-model="fabric.v" v-on:keyup.native="fabricUpdate"></b-input>
                    </span>

                    <span class="thread__count"
                          v-if="fabric.v !== ''"
                    >
                        {{ this.fabric.getWidth() }} threads
                    </span>

                </div>

                <div class="fabric__options">

                    <b-button class="fabric__button" size="is-small" @click.native="uiFabricDownload()">download fabric</b-button>
                    <!--b-button class="fabric__button" size="is-small" disabled>download scene</b-button-->

                    <b-input v-model="fabric_name" size="is-small" placeholder="fabric name"></b-input>

                    <b-button class="fabric__button" size="is-small" @click.native="uiFabricUpdate()">update fabric</b-button>
                    <b-button class="fabric__button" size="is-small" @click.native="uiFabricAdd()">create new fabric</b-button>

                    <!--b-button class="fabric__button" size="is-small" disabled>save palette</b-button-->

                    <b-button class="fabric__button" size="is-small" @click.native="uiFabricRandom()">random</b-button>

                    <b-tooltip class="fabric__tooltip" :label="getLabel('fabric_display')" position="is-top">
                        <b-switch class="fabric__switch" v-model="fabric_display"></b-switch>
                    </b-tooltip>


                    <b-tooltip v-if="fabric_display" class="fabric__tooltip" :label="getLabel('fabric_background_display')" position="is-top">
                        <b-switch class="fabric__switch" v-model="fabric_background_display"></b-switch>
                    </b-tooltip>



                </div>

            </div>

        </div>



        <div id="razor"
             @dragover.prevent="dragOver"
             @dragleave.prevent="dragLeave"
             @drop.prevent="drop($event)"
             :class = "{ 'drag_over': drag_over }"
        ></div>

        <div id="container" :class="{ hide: !fabric_display,
             background: fabric_background_display,
             'panel-closed': !panel_open
        }">

        </div>

        <div v-if="fabric_background_display && fabric_display" id="container-ruler" >

            <div class="ruler__uv">

                <div class="ruler__u">

                    <div class="ruler__100" v-for="n in 30" :dataId="n">

                        <div class="ruler__10" v-for="n in 9" :dataId="n"></div>

                    </div>

                </div>

                <div class="ruler__v">

                    <div class="ruler__100" v-for="n in 30" :dataId="n">

                        <div class="ruler__10" v-for="n in 9" :dataId="n"></div>

                    </div>

                </div>

            </div>

        </div>

        <div class="sidebar__button" v-if="!panel_open" @click="panel_open=1">
            <font-awesome-icon icon="lock" size="1x"

            />
        </div>



        <div class="sidebar" v-if="panel_open">

            <div class="sidebar__head"><!--


                --><ul><li @click="panel_active=1" :class="{active:(panel_active==1)}">color</li><li @click="panel_active=2" :class="{active:(panel_active==2)}">fabric</li><li @click="panel_active=3" :class="{active:(panel_active==3)}">settings</li><li @click="panel_open=!1" class="sidebar__lock">
                        <font-awesome-icon icon="unlock" size="1x"

                        />
                    </li></ul><!--


                --></div>

            <div class="sidebar__content">



                <div :class="{'sidebar__panel':true,'sidebar__panel--active':panel_active==1}">

                    <!-- https://github.com/web-padawan/vanilla-colorful -->

                    <hex-color-picker class="color-picker__module"
                                      :color="colorModify"
                                      @color-changed="handleColorChanged"
                    ></hex-color-picker >

                    <b-input v-model="colorModify"></b-input>

                    <div class="fabric__options">

                        <b-tooltip class="fabric__tooltip" :label="getLabel('fabric_display')" position="is-left">
                            <b-switch class="fabric__switch" v-model="fabric_display"></b-switch>
                        </b-tooltip>

                        <b-tooltip v-if="fabric_display" class="fabric__tooltip" :label="getLabel('fabric_background_display')" position="is-left">
                            <b-switch class="fabric__switch" v-model="fabric_background_display"></b-switch>
                        </b-tooltip>

                    </div>

                    <b-slider v-model="uv_repeat" :min="uv_repeat_min" :max="uv_repeat_max"></b-slider>

                    <div class="display__palette">

                        <palette-component :palette="this.palette"></palette-component>

                    </div>

                </div>



                <div :class="{'sidebar__panel':true,'sidebar__panel--active':panel_active==2}">


                    <div class="fabric__options">

                        <b-tooltip class="fabric__tooltip" :label="getLabel('fabric_display')" position="is-left">
                            <b-switch class="fabric__switch" v-model="fabric_display"></b-switch>
                        </b-tooltip>

                        <b-tooltip v-if="fabric_display" class="fabric__tooltip" :label="getLabel('fabric_background_display')" position="is-left">
                            <b-switch class="fabric__switch" v-model="fabric_background_display"></b-switch>
                        </b-tooltip>

                    </div>

                    <b-slider v-model="uv_repeat" :min="uv_repeat_min" :max="uv_repeat_max"></b-slider>


                    <span v-if="fabric.v !== ''" class="">
                        <div v-for="(f,index) in fabric_store"
                        
                             @click="fabricLoad(f,index)"
                             :class="{fabric__item:true, active: (fabric_index==index)}"
                        >
                            {{ f.name }}
                        </div>
                    </span>

                </div>



                <div :class="{'sidebar__panel':true,'sidebar__panel--active':panel_active==3}">

                    no settings

                </div>



            </div>

        </div>

    </div>

</template>

<script>

    //import Razor from "../objects/visualize/razor";

    //import Layout from "@abstraktion/abstraktion-lib/visualize/layout/Layout";
    //import Tool from "@abstraktion/abstraktion-lib/visualize/tool/Tool";

    import Layout from "@abstraktion/abstraktion-lib/visualize/layout/Layout";
    import Tool from "@abstraktion/abstraktion-lib/visualize/tool/Tool";


    import * as THREE from 'three'

    //import import_texture_fabric_1 from '../../public/abstraktion-fabric_1.png'
    import import_texture_fabric_0 from '../assets/texture/texture.png'



    import import_picture from '../assets/woman_shirt.jpg'
    import import_mask_image_full from '../assets/mask/woman_shirt_mask_full.png'
    import model_json_imported from '../assets/models/shirt/woman_shirt.json'
    import fabric_store_import from '../assets/models/fabric/fabric_store.json'


if (localStorage.getItem("model_picture")
  && localStorage.getItem("model_mask")
  && localStorage.getItem("model_grid")) {
 // load local storage data 
//@todo_001
      if (localStorage.getItem("model_picture") !== null) {
        //model_picture = localStorage.getItem("model_picture");
        import_picture = localStorage.getItem("model_picture");
      }
      if (localStorage.getItem("model_mask") !== null) {
        //model_mask = localStorage.getItem("model_mask");
        import_mask_image_full = localStorage.getItem("model_mask");
      } 
      if (localStorage.getItem("model_grid") !== null) {
        //model_grid = JSON.parse(localStorage.getItem("model_grid"));
        model_json_imported = JSON.parse(localStorage.getItem("model_grid"));
      } 
  }

    import eventbus from "../bus/eventbus";

    // fabric / worker

    import FabricRender from "../objects/fabric/FabricRender";
    import Fabric from "../objects/fabric/Fabric";
    import Worker from "worker-loader!./../objects/fabric/fabric-worker";

    import Jimp from 'jimp'
    import Konva from 'konva'

    // color

    import 'vanilla-colorful'

    import PaletteComponent from '../components/color/palettes.vue'
    import Palette from "../objects/palette/Palette.js";
    import ColorScheme from 'color-scheme'

    import draggable from 'vuedraggable'





    export default {

        name: 'ViewportRazor',

        components: {
            PaletteComponent,
            draggable
        },

        data () {

            return {

                timer:                  0,
                fabricNeedsUpdate:      false,

                layout: null,
                tool: null,

                fabric: {
                    u:'',
                    v:''
                },
                worker: null,

                thread_u_editable: '',
                thread_v_editable: '',

                // ui

                panel_active    :   1,
                panel_open      :   true,

                drag_over       :   false,

                colorModify: '#857f6e',

                paletteActive: null,

                palette: [
                    {a:'#d9cdc0',b:'#857f6e',c:'#fffcf8',d:'#dbd8c8',e:'#b0aa87',f:'#a9b076'},
                //    {a:'#b03c2d',b:'#e5c8a1',c:'#5a7917',d:'#988465',e:'#d3c3a7',f:'#3e6136'},
                //    {a:'#988465',b:'#d3c3a7',c:'#3e6136',d:'#b03c2d',e:'#e5c8a1',f:'#5a7917'},
                //    {a:'#5a7917',b:'#988465',c:'#d3c3a7',d:'#3e6136',e:'#b03c2d',f:'#e5c8a1'},
                ],

                fabric_store:    fabric_store_import.store,

                fabric_index: 0,

                fabric_name: null,

                palette_selected: 0,

                uv_repeat:  5,
                uv_repeat_min: 0,
                uv_repeat_max: 300,
                uv_angle:   0,

                fabric_display: !true,
                fabric_background_display: true,

                edit_u: false,
                edit_v: false,

                started: false,

                isCardModalActive: false,

                words_random: [
                    'Sibilance',
                    'Tranquility',
                    'Loquacious',
                    'Lagniappe',
                    'Epiphany',
                    'Plethora',
                    'Vellichor',
                    'Aurora',
                    'Sanguinolency',
                    'Petrichor',
                    'Delicacy',
                    'Blossoming',
                    'Serendipity',
                    'Abyssopelagic',
                    'Panacea',
                    'Diaphanous',
                    'Languor',
                    'Felicity',
                    'Limerence',
                    'Taradiddle',
                    'Galactic',
                    'Silhouette',
                    'Phosphenes',
                    'Incendiary',
                    'Akimbo',
                    'Quintessence',
                    'Mellifluous',
                    'Syzygy',
                    'Quadrivium',
                    'Abstraktion'
                ]

            }

        },



        watch: {

            uv_repeat( i ) {
                //this.fabricUpdate()

                this.layout.model.setRepeat( this.uv_repeat )

                localStorage.uv_repeat = JSON.stringify( i )

            },

            palette( p ) {
                localStorage.palette = JSON.stringify( p )
            },

            fabric_store: {

                handler: ( f , oldVal) => {
                    localStorage.fabric_store = JSON.stringify( f )
                },
                deep: true


            },

            fabric_index( i ) {

                localStorage.fabric_index = JSON.stringify( i )

            }

            /*palette_selected( index ) {
                localStorage.palette_selected = JSON.stringify(index)
            }*/

        },


        mounted: function () {

            this.storageEventLookup()

            this.init()

            this.$drift.hide()

            /**
             * localstorage
             */
            if (localStorage.palette) {
                this.palette = JSON.parse(localStorage.palette)
                if (localStorage.palette_selected) this.palette_selected = JSON.parse(localStorage.palette_selected)

                if (this.palette_selected>this.palette.length) {
                    this.palette_selected = this.palette.length-1
                    localStorage.setItem('palette_selected',this.palette_selected)
                }

            }
            if (localStorage.fabric_store) {
                this.fabric_store = JSON.parse(localStorage.fabric_store)
                if (localStorage.fabric_index) this.fabric_index = JSON.parse(localStorage.fabric_index)
            }
            if (localStorage.uv_repeat) this.uv_repeat = JSON.parse(localStorage.uv_repeat)

            // coming from color app
            if (localStorage.getItem('abstraktion_fabric_palette_start')) {

                // add if it doesn't exist @todo

                let palette = localStorage.getItem('abstraktion_fabric_palette_start').split(',')

                this.palette.unshift({
                    a: '#'+palette[0],
                    b: '#'+palette[1],
                    c: '#'+palette[2],
                    d: '#'+palette[3],
                    e: '#'+palette[4],
                    f: '#'+palette[5]
                })

                this.palette_selected = 0//this.palette.length-1

                localStorage.setItem('palette_selected',this.palette_selected)
                localStorage.removeItem('abstraktion_fabric_palette_start')

            }

            this.paletteActive = new Palette(
                this.palette[ this.palette_selected ]
            )

            this.tool = new Tool()
            this.tool.displayDotGrid( !true )

            this.layout = new Layout( {
                tool:   this.tool,
                div_id: 'razor',
                is_3d:          !true,
                debug:          true,
                helpers:        !true,
                dot_display:    true,
                background: {
                    color: 0xffffff
                }
            } )

            this.layout.setPicture( import_picture )
            this.layout.setModel( import_mask_image_full , import_texture_fabric_0 , {
                dot: {
                    color: 0xff0000,
                    size: 15
                },
                repeat: 30,
                uv: {
                    u: 20,
                    v: 22,
                },
                import:

                    model_json_imported

            } , ()=>{



            })

            /**
             * fabric
             */

            if (this.fabric_index > this.fabric_store.length)
                // error : reset (localstorage removed)
                this.fabric_index = 0

            let fabric = new Fabric(
                this.fabric_store[ this.fabric_index ].u,
                this.fabric_store[ this.fabric_index ].v,
                this.fabric_store[ this.fabric_index ].name
            )

            this.fabric_name = fabric.getName()

            //console.log(fabric)

            const worker = new Worker()
            //const worker = new Worker('./../objects/fabric/fabric-worker.js',{type:'module'})

            

            worker.onmessage = ( e ) => {

                let data = e.data

                //console.log(data)

                if ( data.stage_json ) {

                    let stage = Konva.Node.create(data.stage_json, 'container')

                    //console.log('stage',stage)

                    //this.download()

                    let dataURL = stage.toDataURL({ pixelRatio: 1 })

                    //console.log(dataURL)

                    let img = new Image()

                    img.onload = () => {

                        let loader = new THREE.TextureLoader()

                        let texture = loader.load( img.src, ( texture ) => {

                            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                            //texture.offset.set( 0, 0 );
                            texture.repeat.set( this.uv_repeat , this.uv_repeat )
                            //texture.rotation = Number(this.angle)

                            this.layout.model.setAngle(
                                this.angle
                            )

                            this.layout.model.setTexture(
                                texture
                            )

                        } )

                        //this.layout.model.getGrid(0).repeat = 10

                    }

                    img.src = dataURL

                    //console.log(img)

                    //console.log('dataURL')
                    //this.layout.model.getGrid(0).modifyTexture( new THREE.TextureLoader().load( img ) )

                    //this.layout.model.getGrid(0).modifyTexture( texture_apply_1 )

                }

            }

            worker.postMessage({
                fabric: fabric,
            })

            this.fabric = fabric
            this.worker = worker

            this.setupBus()
            this.fabricUpdate()

        },

        methods: {

            init() {

                this.$buefy.toast.open({
                    duration: 2000,
                    message: `welcome`,
                    //position: 'is-bottom',
                    //type: 'is-danger'
                })

                const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)

                if (!isChrome)
                this.$buefy.toast.open({
                    duration: 60000*5,
                    message: `ONLY CHROME BROWSER PLEASE`,
                    //position: 'is-bottom',
                    type: 'is-danger'
                })

            },

            storageEventLookup() {

                window.onstorage = () => {
                    let p = localStorage.getItem('abstraktion_palette')
                    //console.log( p )
                    let colors = p.split(',')
                    this.palette[this.palette_selected]= {
                        a: '#' + colors[0],
                        b: '#' + colors[1],
                        c: '#' + colors[2],
                        d: '#' + colors[3],
                        e: '#' + colors[4],
                        f: '#' + colors[5]
                    }
                        
                    this.paletteSelect(this.palette_selected )

                    eventbus.$emit('bus_paletteModifyAllColors', this.palette)

                    this.fabricUpdate()
                }

            },

            getLabel( source ) {

                switch ( source ) {

                    case 'fabric_display':

                        if (this.fabric_display) return 'hide fabric'
                        else return 'show fabric'

                    case 'fabric_background_display':

                        if (this.fabric_background_display) return 'hide ruler and background'
                        else return 'display ruler and background'

                }

            },

            fabricLoad( f , index ) {

                this.fabric_index = index

                this.fabric.setUV(f.u,f.v)
                this.fabric.setName(f.name)
                this.fabric_name = f.name
                this.fabricUpdate()

            },

            threadCopyUtoV() {

                this.fabric.copyUtoV()

                this.fabricUpdate()

            },

            threadCopyVtoU() {

                this.fabric.copyVtoU()

                this.fabricUpdate()

            },

            fabricThreadReorder() {

                this.fabric.updateUFromArray()
                this.fabric.updateVFromArray()
                this.fabricUpdate()


            },

            fabricThreadUReorder() {

                this.fabric.updateUFromArray()
                this.fabricUpdate()

            },

            fabricThreadVReorder() {

                this.fabric.updateVFromArray()
                this.fabricUpdate()

            },

            threadMoveU( id ) {
                this.fabric.threadUMove( id )
                this.fabricUpdate()
            },

            threadMoveV( id ) {
                this.fabric.threadVMove( id )
                this.fabricUpdate()
            },

            setupBus() {

                /**
                 *  bus
                 */

                eventbus.$on('bus_applyPalette', ( o ) => {
                    //console.log(o)
                    this.paletteSelect( o.selected_palette )
                    this.fabricEditableUpdate()
                    //this.fabricUpdate()

                    localStorage.setItem(
                        'abstraktion_palette',
                        (this.palette[this.palette_selected]['a'].replace('#','')
                        +','+this.palette[this.palette_selected]['b'].replace('#','')
                        +','+this.palette[this.palette_selected]['c'].replace('#','')
                        +','+this.palette[this.palette_selected]['d'].replace('#','')
                        +','+this.palette[this.palette_selected]['e'].replace('#','')
                        +','+this.palette[this.palette_selected]['f'].replace('#','')).toUpperCase()
                    )

                })

                eventbus.$on('bus_modifyColorColorPicker', ( o ) => {
                    //console.log(o)

                    this.colorModify = o.selected_color_hex

                })

                eventbus.$on('bus_createPalette', ( o ) => {

                    this.createPalette()
                    this.paletteSelect( 0/*this.palette.length - 1*/ )
                    this.fabricUpdate()

                })

            },

            createPalette() {

                let scheme = new ColorScheme()

                scheme.from_hex( this.colorModify.replace('#','') )         // Start the scheme
                    .scheme('analogic')     // Use the 'triade' scheme, that is, colors
                    // selected from 3 points equidistant around
                    // the color wheel.
                    //.variation('soft')   // Use the 'soft' color variation

                var colors = scheme.colors()

                //console.log(this.colorModify.replace('#',''),colors)

                let color = {
                    a: this.colorModify,
                    b: '#' + colors[0],
                    c: '#' + colors[1],
                    d: '#' + colors[2],
                    e: '#' + colors[3],
                    f: '#' + colors[4]
                }

                this.palette.unshift(color)

            },

            paletteSelect( i ) {

                //console.log(i)

                this.palette_selected = i// || this.palette_selected

                this.paletteActive.setColors(
                    this.palette[ this.palette_selected ]
                )

                this.worker.postMessage({
                    palette :   this.paletteActive,
                    fabric  :   this.fabric
                })

            },

            handleColorChanged( event ) {

                //console.log('color changed')

                this.colorModify = event.target.color

                /**
                 *  change color palette
                 */

                // modify locally

                // @todo : store ?

                // modify : send display

                eventbus.$emit('bus_paletteModifyColor', {
                    modify_color_hex : this.colorModify
                })

                //console.log(this.colorModify)

                //if (this.realtime)
                //    this._uiLayerColorReplace( this.colorModify )

                /*let im = new Image(1,1)

                let texture = new THREE.TextureLoader().load( src )

                //this.layout.model.get(0).grid.setTexture( texture )
                this.layout.model.setTexture( texture )
*/

                //this.layout.model.get(0).grid.material.color.set( parseInt(this.colorModify.slice(1), 16) )

                // @todo this is a demo to set a global tint on texture :
                //this.layout.model.setColor( parseInt(this.colorModify.slice(1), 16) )

                // @todo realtime

                this.fabricNeedsUpdate = true

                if (Number.isInteger(this.timer)) {
                    //console.log('update')
                    this.fabricUpdate()
                    this.fabricNeedsUpdate = false
                }

            },

            dragOver() {
                this.drag_over = true
            },

            dragLeave() {
                this.drag_over = !true
            },

            drop(e){

                let files = e.dataTransfer.files

                if (files.length == 0) {
                    this.$buefy.toast.open({
                        duration: 2000,
                        message: `bad file format`,
                        //position: 'is-bottom',
                        type: 'is-danger'
                    })
                    return
                }

                let file = files[0]

                if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
                    this.$buefy.toast.open({
                        duration: 2000,
                        message: `bad file format, only jpg or png`,
                        //position: 'is-bottom',
                        type: 'is-danger'
                    })
                    return
                }

                let reader = new FileReader()

                reader.onload = f => {
                    // f.target.result contains the base64 encoding of the image
                    let src = f.target.result

                    // apply texture

                    let texture = new THREE.TextureLoader().load( src )

                    //this.layout.model.get(0).grid.setTexture( texture )
                    this.layout.model.setTexture( texture )

                    this.drag_over = false

                }



                if (file !== 'undefined') {
                    this.$buefy.toast.open({
                        duration: 2000,
                        message: `` + file.name,
                        //position: 'is-bottom',
                        //type: 'is-danger'
                    })
                    reader.readAsDataURL(file)
                }
                else {
                    this.$buefy.toast.open({
                        duration: 2000,
                        message: `Bad file format`,
                        //position: 'is-bottom',
                        type: 'is-danger'
                    })
                }

            },



            /**
             *  update fabric : call external worker
             */
            fabricUpdate() {

                //console.log('update')

                let a = this.fabric.u.substring(this.fabric.u.length - 1)

                //console.log('>>' + a)

                if (a.match(/^[a-z]+$/)) {

                    //this.fabric.setUV( this.thread_u )
                    //this.fabric.threadUMove(1)

                    // generate this.thread_u_editable

                    this.fabric.update()    // important

                    this.fabricEditableUpdate()

                    this.worker.postMessage({
                        fabric:     this.fabric,
                        palette:    this.paletteActive
                    })

                    //console.log(this.fabric)

                }

            },

            fabricEditableUpdate() {
                this.thread_u_editable = this.getThreadEditable( this.fabric.u )
                this.thread_v_editable = this.getThreadEditable( this.fabric.v )
            },

            getThreadEditable( uv ) {

                let decode = uv.match(/([0-9]+)([a-z])/g),
                    result = [] //''

                //console.log ('decode',decode)

                if (decode) {

                    decode.forEach( ( x ) => {

                        let xx = x.match( /([0-9]+)([a-z])/ )

                        //console.log( xx , x )

                        result.push({
                            color:  this.palette[ this.palette_selected ][ xx[2] ],
                            thread: xx[2],
                            count:  xx[1]
                        })

                    } )

                }

                return result

            },



            download() {

                let width = this.fabric.width , height = this.fabric.height


                let stage = new Konva.Stage({
                    width:  width,
                    height: height,
                    container: 'container'
                })

                let fabricRender = new FabricRender(
                    stage ,
                    this.fabric ,
                    this.palette[this.palette_selected]
                )

                fabricRender.render()

                // bug : retrieving group instead of layer...
                let dataURLU = fabricRender.getLayerU().toDataURL({ pixelRatio: 1 })
                let dataURLV = fabricRender.getLayerV().toDataURL({ pixelRatio: 1 })

                //console.log( 'dataURLU', dataURLU.replace("data:image/png;base64,",""), dataURLV )

                //console.log( width, height )

// https://github.com/oliver-moran/jimp/issues/231
dataURLU = dataURLU.replace("data:image/png;base64,","")
dataURLV = dataURLV.replace("data:image/png;base64,","")

                // jimp image with mask






                new Jimp(
                    width,
                    height,
                    0x000000ff, (err, mask) => {

                        //console.log('mask',err,mask,'///')

                        for (let y=0 ; y < height ; y++) {
                            //console.log(y%2)
                            for (let x=0 ; x < width ; x++) {

                                if (x%2==0)
                                    mask.setPixelColor( 0xffffffff , x + y%2 , y )

                            }

                        }


                                Jimp.read(Buffer.from(dataURLU, 'base64'))
                                    .then(imageU => {

                                        //console.log('imageU',imageU)
                                Jimp.read(Buffer.from(dataURLV, 'base64'))

                                            .then(imageV => {
                                                //console.log('image',imageV)

                                                imageV.mask( mask )
                                                //console.log('mask2',mask)

                                                imageU.composite(imageV,0,0)

                                                imageU.getBase64Async(Jimp.MIME_PNG)
                                                    .then(v => {
                                                //console.log('v',v)

                                                        this.downloadURI( v , 'abstraktion_' + this.fabric_name.replaceAll(' ','_') + '.png' )

                                                    })

                                            })
                                            .catch(err => {
                                        //console.log('err2',err)
                                    })
                                    })
                                    .catch(err => {
                                        //console.log('err',err)
                                    })


                    })

                // image.setPixelColor(hex, x, y)

                // @debug :
                //this.downloadURI( dataURLU , 'fabric_u.png' )
                //this.downloadURI( dataURLV , 'fabric_v.png' )

                return false

            },



            downloadURI(uri, name) {

var link = document.createElement('a');
                link.download = name;
                link.href = uri;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                //delete link;

            },


            uiFabricAdd() {

                this.fabric_store.unshift(
                    {
                        name: this.fabric_name,
                        u: this.fabric.u,
                        v: this.fabric.v,
                    }
                )

                this.fabric_index = 0

            },


            uiFabricUpdate(  ) {

                this.fabric_store[ this.fabric_index ] =
                    {
                        name: this.fabric_name,
                        u: this.fabric.u,
                        v: this.fabric.v,
                    }

                //console.log(this.fabric_index,this.fabric_store[ this.fabric_index ],this.fabric_store)

                localStorage.fabric_store = JSON.stringify( this.fabric_store )

                this.$forceUpdate()


            },


            uiFabricDownload() {

                this.download()

            },


            uiFabricRandom() {

                this.fabric.random()
                this.fabricUpdate()
                this.fabric_name = '' + this.words_random[Math.floor(Math.random() * this.words_random.length)].toLowerCase()

            }

        }
    }

</script>

















<style lang="scss">


    .modal-background {
        top: 20px;
    }

    .modal-close {
        top: 55px !important;
    }

    .noselect {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .ux-input {

        overflow-x: auto;
        position:fixed;
        bottom: 0px;
        left:0;
        width: calc( 100% - 216px );
        z-index: 20;
        background-color: rgba(255, 255, 255, 0.98);

        &.full {
            width:100%;
        }

        .fabric {

            &__inject {

                //padding: 4px;
                cursor: pointer;

                svg {
                    width:20px;
                    height:20px;
                    padding: 4px 2px 0;
                }

                color: #636363;
                transition: all 800ms;
                opacity: .5;

                &:hover {
                    opacity: 1;
                }

                &.selected {
                    color: #000000;
                    opacity: 1;
                }

            }

            &__editable {
                padding:15px;
                font-size: .8rem;

                span.thread__unit {
                    //margin: 0 2px;
                    padding: 4px;
                    color: #fff;
                    cursor: pointer;
                }

                span.thread__unit-thread {
                    margin: 0 2px;
                }

                span.thread__unit-count {
                    margin: 0 2px;
                }

            }

            &__draggable {
                display: contents;
            }

            &__options {

                display: flex;
                padding: 10px 0;

            }

            &__tooltip {
                padding: 4px 0;
            }

            &__button, &__switch {

                margin: 0px 5px;

            }

            &__formula {
                min-height: 27px;
            }

        }

    }

    #razor {

        height: calc( 100vh - 40px );
        background-color: #fff;

        &.drag_over {
            filter: brightness(40%);
        }

    }

    .razor__layout {

        height: calc( 100vh - 40px );
        background-color: #fff;

        canvas {
            height: calc( 100vh - 40px );
        }

    }

    #container-ruler {

        //background-color: rgba(112, 255, 29, 0.28);
        position: fixed;
        z-index: 10;
        top: 40px;
        left: 0;

        &.hide {
            display:none;
        }

        .ruler__uv {

            width: 100%;
            height: calc(100vh - 40px);


        }

        .ruler__u {

            display: flex;
            opacity: .4;

            .ruler__100 {

                height:30px;
                border-left: 1px solid #000;
                margin-left: 9px;
                display: flex;

                &:first-child {
                    margin-left: -1px;
                    border-top: 1px solid rgba(0, 0, 0, 0.06);

                }

            }

            .ruler__10 {

                height:10px;
                border-left: 1px solid #000;
                margin-left: 9px;

            }

        }

        .ruler__v {

            //display: flex;
            opacity: .4;

            position: absolute;
            left: 0;
            top: 0;

            .ruler__100 {

                height:auto;
                width:30px;
                border-top: 1px solid #000;
                margin-top: 9px;
                //display: flex;

                &:first-child {
                    margin-top: -1px;
                    border-top: 1px solid rgba(0, 0, 0, 0.06);

                }

            }

            .ruler__10 {

                //height:9px;
                width:10px;
                border-top: 1px solid #000;
                margin-top: 9px;

            }

        }

    }

    #container {

        position: fixed;
        z-index: 10;
        top: 40px;
        left: 0;
        //background-color: #000;
        //background-color: rgba(255, 0, 0, 0.26);



        .konvajs-content canvas:nth-child(2) {

            -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAMAAABFaP0WAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjBCQzNGNkY5QTVGMTFFQUE3ODM4RDdFNjUzNUEyMzciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjBCQzNGNzA5QTVGMTFFQUE3ODM4RDdFNjUzNUEyMzciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyMEJDM0Y2RDlBNUYxMUVBQTc4MzhEN0U2NTM1QTIzNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyMEJDM0Y2RTlBNUYxMUVBQTc4MzhEN0U2NTM1QTIzNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuSoz6cAAAAGUExURQAAAP///6XZn90AAAACdFJOU/8A5bcwSgAAAA9JREFUeNpiYGAEQoAAAwAADAADK26cEwAAAABJRU5ErkJggg==);
            mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAMAAABFaP0WAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjBCQzNGNkY5QTVGMTFFQUE3ODM4RDdFNjUzNUEyMzciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjBCQzNGNzA5QTVGMTFFQUE3ODM4RDdFNjUzNUEyMzciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyMEJDM0Y2RDlBNUYxMUVBQTc4MzhEN0U2NTM1QTIzNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyMEJDM0Y2RTlBNUYxMUVBQTc4MzhEN0U2NTM1QTIzNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuSoz6cAAAAGUExURQAAAP///6XZn90AAAACdFJOU/8A5bcwSgAAAA9JREFUeNpiYGAEQoAAAwAADAADK26cEwAAAABJRU5ErkJggg==);

        }

        &.hide {
            display: none;
        }

        &.background {
            background-color: rgba(255, 255, 255, 0.95);
            width: calc( 100% - 216px );
            height: 100%;
        }

        &.panel-closed {
            width: 100%;
        }

    }

    .sidebar {

        width: 216px;
        height: calc( 100vh - 40px );
        background-color: rgba(255, 255, 255, 0.95);
        position:fixed;
        top: 40px;
        right:0;
        z-index: 20;

        border-left: 1px #eee solid;

        font-size: .8rem;

        &__button {

            position:fixed;
            top: 40px;
            right:0;
            width: 30px;
            background-color: #cc0020;
            color:#fff;
            border: none;
            padding: 8px 8px;
            text-align: center;
            font-size: .8rem;
            cursor: pointer;
            transition: color 1s;
            &:hover {
                filter: brightness(110%);
            }
            z-index: 30;

        }

        &__lock {
            transition: color 1s;

            &:hover {
                filter: brightness(110%);
                transition: color 1s;
            }
        }

        &__head {

            ul {

                font-size: .8rem;
                border-bottom: 1px #eee solid;

                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none;

                li {

                    width: calc( 33.33% - 10px );

                    display: inline-block;
                    //background-color: #f00;
                    padding: 8px 8px;
                    text-align: center;
                    border-right: 1px #eee solid;
                    transition: 1s all;
                    cursor: pointer;

                    &:hover, &.active {
                        background-color: #333;
                        color:#fff;
                    }

                    &:last-of-type {
                        width: 30px;
                        background-color: #333;
                        color:#fff;
                        border: none;
                    }

                }

            }

        }

        &__content {
            height: calc( 100vh - 92px );
        }

        &__panel {

            height:100%;
            margin:8px;
            display: none;
            //background-color: #eee;



            .display__palette {

                height: calc( 100vh - 360px );
                margin-top: 15px;
                overflow-y: auto;
                overflow-x: hidden;

            }


            .fabric {


                &__item {

                    cursor: pointer;
                    transition: all 500ms;

                    &:hover {
                        opacity: .5;
                    }

                    &.active {
                        font-weight: 900;
                    }

                }

                &__options {

                    margin-bottom: 10px;

                }

            }

        }

        &__panel--active {

            display: block;


        }

    }


</style>