export default class Fabric {

    constructor( u , v , name ) {

        this.u = u || '40a40b2a2b2a2b2c2b2a2b2a40d40e18f4e18f'
        this.v = v || '40a40b2a2b2a2b2c2b2a2b2a40d40e18f4e18f'

        this.threadU = ''
        this.threadV = ''

        this.threadUArray = []
        this.threadVArray = []

        this.width = 0
        this.height = 0

        this.name = name || 'abstraktion'

        this.update()

    }

    getName() {
        return this.name
    }

    setName( name ) {
        return this.name = name
    }

    copyUtoV() {
        this.v = this.u
        this.update()
    }

    copyVtoU() {
        this.u = this.v
        this.update()
    }



    setUV( u , v ) {

        this.u = u
        this.v = v || u
        this.update()

    }



    getU() {
        return this.u
    }

    getV() {
        return this.v
    }



    setU( u ) {

        this.u = u

        this.update()

    }



    setV( v ) {

        this.v = v

        this.update()

    }


    /**
     * moves thread ex 100a into thread(U/V)Array & update u/v & threadU/V
     */
    threadUMove( id ) {

        if ( id > -1 && typeof(this.threadUArray[id+1]) !== 'undefined')
            this.threadUArray[id] = this.threadUArray.splice(id+1, 1, this.threadUArray[id])[0]

        this.encodeU()

    }

    threadVMove( id ) {

        if ( id > -1 && typeof(this.threadVArray[id+1]) !== 'undefined')
            this.threadVArray[id] = this.threadVArray.splice(id+1, 1, this.threadVArray[id])[0]

        this.encodeV()

    }


    encodeU() {

        let string = ''

        this.threadUArray.forEach( e => {
            //console.log(e)
            string += e.quantity + e.id
        })

        this.u = string

    }


    encodeV() {

        let string = ''

        this.threadVArray.forEach( e => {
            string += e.quantity + e.id
        })

        this.v = string

    }


    random() {

        let f = this._random()

        this.u = f
        this.v = f

    }

    _getRandom( min , max ) {

        return Math.floor(Math.random() * (max - min + 1)) + min

    }

    _getFabricRandom() {

        const fabric = 'abcdef'

        return fabric.substr( this._getRandom( 0 , 5 ) , 1 )

    }

    _random() {

        let uv = '', size = 0 , sizeFull = 0

        let sequence = [20,2,1,2,6,30,1,1,1,4,1]

        for( let i = 0 ; i < 4 ; i++ ) {

            for( let ii = 0 ; ii < 5 ; ii++ ) {

                size = this._getRandom(1,4) * sequence[ii]

                sizeFull += size

                uv += size + this._getFabricRandom()

            }

            //console.log(sizeFull)

        }

        if (sizeFull<512) {

            uv += (512-sizeFull) + this._getFabricRandom()

        }

        //console.log(800-sizeFull,sizeFull,'***')

        return uv

    }

    getWidth() {
        return this.threadV.length
    }

    getHeight() {
        return this.threadU.length
    }

    /**
     * used by draggable
     */
    updateUFromArray() {

        this.u = ''

        this.threadUArray.forEach( ( x ) => {
            this.u = this.u + x.quantity + x.id
        })

        //this.update()

    }

    updateVFromArray() {

        this.v = ''

        this.threadVArray.forEach( ( x ) => {
            this.v = this.v + x.quantity + x.id
        })

        //this.update()

    }

    update() {

        //console.log('fabric:update')

        let decodeU = this.decode( this.u ),
            decodeV = this.decode( this.v )

        this.threadU = decodeU.string
        this.threadV = decodeV.string

        this.threadUArray = decodeU.array
        this.threadVArray = decodeV.array

        this.width = this.getWidth()
        this.height = this.getHeight()

    }

    decode( uv ) {

        let regex = /([0-9]+)([a-z])/g

        let decode = uv.match(regex),
            result = '',
            array = []

        //console.log (decode)

        if (decode) {

            let xx = '',
                threadId = null,
                threadQuantity = null

            decode.forEach( ( x ) => {

                xx = x.match( /([0-9]+)([a-z])/ )

                threadId = xx[2]
                threadQuantity = xx[1]

                //console.log( threadQuantity +':'+ threadId )

                array.push({
                    id: threadId,
                    quantity: threadQuantity
                })

                for ( let i=0 ; i < threadQuantity ; i++ ) {
                    result += threadId
                }


            })

            //console.log(result)


        }
        else {
            console.log('error')
        }

        return {
            string: result,
            array: array
        }

    }


}