<template>
  <div>
    <!--abstraktion-navigation :modules="modules" /-->

    <headbar></headbar>
    <viewport-razor ref="razor"></viewport-razor>
  </div>
</template>

<script>
// import components
//import eventbus from './bus/eventbus'
import Headbar from "../components/headbar.vue";
//import Viewport from "./components/viewport";
import ViewportRazor from "../components/viewport-razor.vue";
//import AbstraktionNavigation from "../components/AbstraktionNavigation.vue";
export default {
  name: "App",
  components: {
    ViewportRazor,
    //AbstraktionNavigation,
    Headbar,
  },

  //   beforeMount() {
  //     console.log(document.getElementById("app"), "***");
  //     if (
  //       typeof document.getElementById("app") !== "undefined" &&
  //       typeof document.getElementById("app").dataset !== "undefined" &&
  //       typeof document.getElementById("app").dataset.modules !== "undefined"
  //     )
  //       this.modules = document.getElementById("app").dataset.modules.split(",");
  //   },
  data() {
    return {
      //modules: [],
    };
  },

  watch: {},

  methods: {},

  created() {},

  mounted() {},
};
</script>

<style lang="scss">
</style>