/**

router

this.$store.dispatch("logout", {});
this.$store.getters.isClient


*/
import Vue from "vue";
import VueRouter from "vue-router";
import Master from "../views/App.vue";
import { auth } from "../firestore";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/app",
    name: "Master",
    component: Master,
    meta: {
      requiresAuth: true,
      requiresClient: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/Settings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("../views/checkout/stripe.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  { path: "*", component: () => import("../views/404.vue") },
];

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// navigation guard to check for logged in users
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresClient = to.matched.some((x) => x.meta.requiresClient);
  if (await (requiresAuth && !auth.currentUser)) {
    next("/login");
  } else if (requiresClient && !store.getters.isClient) {
    next("/profile");
  } else {
    next();
  }
});

export default router;
