<template>
  <div id="app">
    <div class="container-fluid">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "Master",
  components: {},
};
</script>

<style lang="scss">
@import "scss/app.scss";
@import "scss/main.scss";
</style>
